$COMPANY: "sgb";
            $NODE_ENV: "production";
            $secondary: #e4ab26;;
            $slight   : #FCD253;;
            $sdark    : #b98b00;;
            $stext    : #000000;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

:global(body.PrivateLayoutStyles-menu_open) {
	overflow: hidden;
}

:export { mobile_width:  $GlobalStyles-screen-desktop - 1px };