$COMPANY: "sgb";
            $NODE_ENV: "production";
            $secondary: #e4ab26;;
            $slight   : #FCD253;;
            $sdark    : #b98b00;;
            $stext    : #000000;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

$RealAccountComponentStyles-body_top: $GlobalStyles-standard_space * 9;

.wrapper {
	height : 100%;
	padding: $GlobalStyles-standard_space*2 0px;
}

.background_filler {
    @include ColorStyles-sdark;
    position  : fixed;
    top       : 50%;
    transform : translateY(78px);
    bottom    : 0px;
    width     : 100%;
}

.main_container {
	@include GlobalStyles-slant_container_top($RealAccountComponentStyles-body_top);
	width: 100%;
}

.content_wrapper {
	padding   : $GlobalStyles-standard_space*2; 
	position  : relative;
	width     : 100%;
	display   : block;
	z-index   : 2; 
}

// temporary
.content_body {
	//min-height: 200px;
	position: relative;
	padding-top   : $GlobalStyles-standard_space*2;
	padding-bottom: $GlobalStyles-standard_space;
	padding-left  : $GlobalStyles-standard_space*2;
	padding-right : $GlobalStyles-standard_space*2;
}

.content_footer_text {
	text-align    : center; 
	padding-top   : $GlobalStyles-standard_space*2; 
	margin-top    : $GlobalStyles-standard_space;
	position      : relative; 
	max-width     : 1058px; 
	margin        : 0 auto;
}

.content_footer {
	text-align    : center; 
	padding-top   : $GlobalStyles-standard_space*2; 
	padding-bottom: $GlobalStyles-standard_space*2; 
	padding-right : $GlobalStyles-standard_space*2; 
	padding-left  : $GlobalStyles-standard_space*2;
	margin-top    : $GlobalStyles-standard_space;
	position      : relative; 

	&::before {
		@include ColorStyles-pdark-fading-edges;
		@include GlobalStyles-pseudo;
		height: 1px;
		width : 100%;
		top   : 0px;
	}

	.timer {
		color: rgba($ColorStyles-ptext, .8);

	    .timer_wrapper {
			@include GlobalStyles-clearfix;
	    	padding: 0px $GlobalStyles-standard_space;
	    	display: inline-block;
	    }
	}

	.buttons {
		@include GlobalStyles-clearfix;
		margin-top: $GlobalStyles-standard_space*2;
		text-align: left;

		:global(.RealAccountComponentStyles-back_button) {
			position: relative;
			display : inline-block;

			a {
				@include GlobalStyles-secondary_button();
			}

		}

		:global(.RealAccountComponentStyles-submit_button) {
			float: right;
		}
	}
}

:global(.RealAccountComponentStyles-timer_label), :global(.RealAccountComponentStyles-timer) {
	display: inline-block;
	padding: 0px $GlobalStyles-standard_space/4;
}

@media all and (min-width: $GlobalStyles-screen-desktop) {

	.content_wrapper {
		@include GlobalStyles-floating_slight_card(true);
	}

	.content_body {
		min-height: 300px;
	}
}

@media all and (max-width: $GlobalStyles-screen-desktop - 1px) {

	// <withbackground>
	// .background_filler {
 //    	@include ColorStyles-s;
 //    	top: $GlobalStyles-standard_space*20;
	// }

	// .main_container {
	// 	@include GlobalStyles-slant_container_top(0px);
	// 	@include GlobalStyles-form_secondary_theme;
	// 	@include GlobalStyles-link_secondary_theme;
	// 	color: $ColorStyles-stext;
	// 	width: 100%;
	// }

	// .content_wrapper {
	// 	padding: $GlobalStyles-standard_space 0px;
	// 	padding-top: $GlobalStyles-standard_space*4;
	// }

	// .content_title {
	// 	color: lighten($ColorStyles-secondary,60%);
	// }

	// .content_subtitle {
	// 	font-size     : $GlobalStyles-standard_font - 3px;
	// }


	// .content_footer {

	// 	.timer {
	// 		color: rgba($ColorStyles-stext, .7);
	// 	}


	// 	.buttons {
	// 		:global(.RealAccountComponentStyles-back_button) {

	// 			a {
	// 				@include GlobalStyles-form_secondary_theme_secondary_button();
	// 			}

	// 		}
	// 	}
	// }

	// <nobackground>
	.background_filler {
		@include ColorStyles-plight;
	}

	.content_wrapper {
		padding: $GlobalStyles-standard_space*2 0px;
	}

	.content_body {		
		padding-left  : $GlobalStyles-standard_space;
		padding-right : $GlobalStyles-standard_space;
	}

	.content_footer {
		padding-bottom: $GlobalStyles-standard_space; 
		padding-right : $GlobalStyles-standard_space; 
		padding-left  : $GlobalStyles-standard_space;
	}

	.wrapper {
		height : 100%;
		padding: 0px;
	}

}

@media all and (max-width: $GlobalStyles-screen-mobile) {
	.content_body {		
		padding-left  : 0px;
		padding-right : 0px;
	}

	.content_footer {
		padding-bottom: 0px;
		padding-left  : 0px;
		padding-right : 0px;
	}
}

@media all and (max-width: 420px) {

	.content_footer {

		.buttons {

			:global(.RealAccountComponentStyles-back_button) {
				width:100%;

			}
		}
	}
}