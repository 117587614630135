$COMPANY: "sgb";
            $NODE_ENV: "production";
            $secondary: #e4ab26;;
            $slight   : #FCD253;;
            $sdark    : #b98b00;;
            $stext    : #000000;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

$RegistrationStyles-ad_width            : 300px;
$RegistrationStyles-body_top            : $GlobalStyles-standard_space * 10;
$RegistrationStyles-header_height_mobile: 60px + ($GlobalStyles-standard_space*2);

.header {

	.logo_container {
		position      : relative;
		vertical-align: middle;
		display       : inline-block;
    	line-height   : 0px;
	}
}

.body {
	@include GlobalStyles-slant_container_top($RegistrationStyles-body_top);

	.body_content_wrapper {
		@include GlobalStyles-clearfix;
		position  : relative;
	}
}

.main_display {
	padding: $GlobalStyles-standard_space*2;
}

.main_display_boundary {
	display: inline-block;
	padding: ($GlobalStyles-standard_space * 2) 0px;
	z-index: 1;
}

.captcha_wrapper {
	text-align: center;
}

.adheader_container {
	position   : absolute;
	height     : 100%;
	width      : $RegistrationStyles-ad_width;
	right      : $GlobalStyles-standard_space;

	.main_display {
		background: darken($ColorStyles-plight,1%);
		height    : 100%;
		border    : 1px solid darken($ColorStyles-plight,10%);
		color     : $ColorStyles-ptext;
		overflow  : auto;

		@include GlobalStyles-floating_slight_card;
	}
}

.form_container {
	position     : relative;
	padding-right: $RegistrationStyles-ad_width + ($GlobalStyles-standard_space*2);
	width        : 100%; 

	.main_display {
		@include GlobalStyles-floating_slight_card;
	}
}

.adfooter_wrapper {
	
	.adfooter_container {
		position: relative;
	}	
}

.has_no_AdHeader {
	
	.form_container, .header {
		max-width : 868px;
		padding-right: 0px;
		margin: 0 auto;
		display: block;
	}

	.adheader_container {
		display: none;
	}
}

@media all and (min-width: $GlobalStyles-screen-desktop) {

	.header {
		padding-top: $GlobalStyles-standard_space*2 !important;
	}

	.body {

		.body_content_wrapper {
			@include GlobalStyles-container;
		}
	}

}

@media all and (max-width: $GlobalStyles-screen-desktop - 1px) {

	.header {
		position   : absolute !important;
		top        : 0px; 
		height     : $RegistrationStyles-header_height_mobile;
		line-height: $RegistrationStyles-header_height_mobile;
		padding-top: 0px;
		text-align : center;
		z-index    : 2;
	}

	.logo_container {

		:global(.ImageStyles-image_size) {
			max-height: $RegistrationStyles-header_height_mobile - ($GlobalStyles-standard_space*2);
		}
	}

	.body {
		padding-top: $RegistrationStyles-header_height_mobile;

		&::before, &::after {
			display: none !important;
		}

		.body_background {
			display: none;
		}

		.adheader_container {
			position: relative;
			width   : 100%;
			left    : 0px;

			.main_display {
				background: darken($ColorStyles-plight,5%);
				padding   : 0px;
			}
		}

    	// <withbackground>
		// .form_container {
		// 	@include GlobalStyles-slant_container_top(0px,'.main_display');
		// 	@include GlobalStyles-slant_container('.main_display');
		// 	padding: 0px;

		// 	.main_display {
		// 		background : transparent;
		// 		padding    : $GlobalStyles-standard_space 0px;
		// 		padding-top: $GlobalStyles-standard_space * 4;
		// 		border     : 0px;
		// 	}
		// }

		.body_content_wrapper {
			height: auto;
		}

		.main_display {
    		box-shadow: none;
		}

		.main_display_boundary {
			@include GlobalStyles-container;
			padding: $GlobalStyles-standard_space;
		}

    	// <nobackground>
		.form_container {
			border-top   : 1px solid darken($ColorStyles-plight,5%);
			border-bottom: 1px solid darken($ColorStyles-plight,5%);
		}

		.form_container {
			padding: 0px;

			.main_display {
				background : transparent;
				padding    : $GlobalStyles-standard_space*2 $GlobalStyles-standard_space;
				border     : 0px;
			}
		}
	}


}