$COMPANY: "sgb";
            $NODE_ENV: "production";
            $secondary: #e4ab26;;
            $slight   : #FCD253;;
            $sdark    : #b98b00;;
            $stext    : #000000;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

.link_button {
    @include GlobalStyles-button_width_media;
	padding: $GlobalStyles-standard_space;
	display: inline-block;
}

.login_button {

	a {
		@include GlobalStyles-button;
	}
}

.message_body {
	text-align: center;
	margin    : $GlobalStyles-standard_space*2 0px; 

	&.message_error {
		color: $ColorStyles-perror;
	}
}

.icon {
	font-size    : $GlobalStyles-standard_font * 3;
	display      : inline-block;
	margin       : 0 auto;
    height       : 60px;
    line-height  : 72px;
    width        : 60px;
	// color        : $ColorStyles-stext;
	color        : #ffff;
	border-radius: 50%;
	text-align   : center;

	&.message_success {
		background: $ColorStyles-ssuccess;
	}

	&.message_error {
		background: $ColorStyles-serror;
	}

	&.message_info {
		background: $ColorStyles-secondary;
	}
}

.onpage {
	padding   : $GlobalStyles-standard_space * 2;
	text-align: center;
	margin    : 0 auto;
	position  : absolute;
	top       : 0px;
	width     : 100%; 

	.onpage_container {
		max-width: 400px; 
		padding  : $GlobalStyles-standard_space * 2;
		display  : inline-block;

		&.message_success {
			background: rgba($ColorStyles-ssuccess,.05);
		}

		&.message_error {
			background: rgba($ColorStyles-serror,.05);
		}

		&.message_info {
			background: rgba($ColorStyles-sblue,.05);
		}

		:global(.AppStyles-notif_id) ~ :global(.AppStyles-notif_en) { 
			margin-top: $GlobalStyles-standard_space;
		}

	}

}

@import '~theme/components/messagedisplay/scss/MessageDisplayStyles.module';