$COMPANY: "sgb";
            $NODE_ENV: "production";
            $secondary: #e4ab26;;
            $slight   : #FCD253;;
            $sdark    : #b98b00;;
            $stext    : #000000;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

.image_wrapper {
	position   : relative;
	display    : inline-block;
	line-height: 0px;

	&.loading, &.is_broken {
		@include ColorStyles-p(.2);
	}
}

.has_ratio {
	@include GlobalStyles-center(absolute);
}

.img_tag {
	max-width : 100%;
	max-height: 100%;
}

.place_holder {
	opacity  : 0;
	height   : 100%;
	width    : 100%; 
    max-width: 100%;
}