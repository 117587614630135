$COMPANY: "sgb";
            $NODE_ENV: "production";
            $secondary: #e4ab26;;
            $slight   : #FCD253;;
            $sdark    : #b98b00;;
            $stext    : #000000;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

$ImageStyles-width : 449;
$ImageStyles-height: 90;
$ImageStyles-vertical_width : 254;
$ImageStyles-vertical_height: 139;

.logo_wrapper {
	max-width  : 100%;
	padding    : 0 $GlobalStyles-standard_space;
    position   : relative;
    line-height: 0px;
    display    : inline-block;

	.logo {
		width: 100%;

		:global(.ImageStyles-image_size), img {
			height: $ImageStyles-height + px;
			width : auto;
		}
	}

	.logo_vertical {
		width: 100%;

		:global(.ImageStyles-image_size) {
			height:$ImageStyles-vertical_height + px;
			width : auto;
		}
	}
}

:export { logo_height: $ImageStyles-height };
:export { logo_width : $ImageStyles-width  };
:export { vertical_logo_height: $ImageStyles-vertical_height };
:export { vertical_logo_width : $ImageStyles-vertical_width  };
